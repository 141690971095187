import { CarouselSliderSlideChangeEvent } from "../slider/carousel-slider-slide-change-event";
import { CarouselAdditionInitializationBlock } from "../carousel-handles";
import { currentCarouselQuerySelectorAll } from "../selector/current-selector";

const SHOW_ON_SLIDE_ACTIVE_CLASS_NAME = "active" as const;

/**
 * Initializes elements with property `data-carousel-show-on-slide`.
 *
 * They'll get the class name {@link SHOW_ON_SLIDE_ACTIVE_CLASS_NAME},
 * if and only if the value of that attributes equals the current slide number.
 */
export const initializeShowOnSlideElements: CarouselAdditionInitializationBlock =
  (rootElement, controller) => {
    controller.addEventListener(CarouselSliderSlideChangeEvent.KEY, (event) =>
      updateActiveStateOfElements(rootElement, event.index)
    );

    updateActiveStateOfElements(rootElement, controller.getCurrentIndex());
  };

const updateActiveStateOfElements = (
  rootElement: HTMLElement,
  updatedIndex: number
) => {
  for (const showOnSlideElement of currentCarouselQuerySelectorAll(
    rootElement,
    `[data-carousel-show-on-slide="${updatedIndex}"]:not(.${SHOW_ON_SLIDE_ACTIVE_CLASS_NAME})`
  )) {
    showOnSlideElement.classList.add(SHOW_ON_SLIDE_ACTIVE_CLASS_NAME);
  }

  for (const hideOnOtherSlideElement of currentCarouselQuerySelectorAll(
    rootElement,
    `.${SHOW_ON_SLIDE_ACTIVE_CLASS_NAME}[data-carousel-show-on-slide]:not([data-carousel-show-on-slide="${updatedIndex}"])`
  )) {
    hideOnOtherSlideElement.classList.remove(SHOW_ON_SLIDE_ACTIVE_CLASS_NAME);
  }
};
