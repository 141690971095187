import "./hero-carousel-styles.scss";
import {
  CarouselAdditionInitializationBlock,
  forEachRegisteredCarouselController,
} from "../carousel/carousel-handles";
import { initializeShowOnSlideElements } from "../carousel/depending-elements/show-on-slide";

const initializeHeroCarouselFeatures: CarouselAdditionInitializationBlock = (
  carouselRootElement,
  controller
) => {
  if (!carouselRootElement.matches(":scope.hero__carousel")) {
    return;
  }

  initializeShowOnSlideElements(carouselRootElement, controller);
  promisedSlideVisibilityTrackingInitializationFunction?.then(
    (initializeSlideVisibilityTracking) =>
      initializeSlideVisibilityTracking(carouselRootElement, controller)
  );
};

// Currently, this functionality is only required as a fallback for User-Agents,
// that do not support scroll-driven-animations.
const promisedSlideVisibilityTrackingInitializationFunction = CSS.supports(
  "animation-timeline: view()"
)
  ? undefined
  : import("../carousel/slider/slider-visible-element").then(
      (module) => module.initializeSlideVisibilityTracking
    );

forEachRegisteredCarouselController(initializeHeroCarouselFeatures);
